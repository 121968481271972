export default defineNuxtRouteMiddleware(async (to, _from) => {
  //   console.info("onboarding.global.ts", to.name);
  //   if (to.name === "i-hash") {
  //     // console.info("Installation route, skipping check");
  //     return;
  //   } else if (to.name === "bug_report-bugReportQrCode") {
  //     // console.info("Bug report route, skipping check");
  //     return;
  //   } else if (to.name === "onboarding") {
  //     // console.info("Onboarding route, skipping check");
  //     return;
  //   } else if (to.name === "setup") {
  //     // console.info("Setup journey route, skipping check");
  //     return;
  //   } else if (to.name === "info") {
  //     // console.info("Info route, skipping check");
  //     return;
  //   } else if (to.name === "settings") {
  //     // console.info("Info route, skipping check");
  //     return;
  //   } else if (to.name === "journey-shared-id") {
  //     // console.info("Journey shared route, skipping check");
  //     return;
  //   } else if (to?.name && to?.name?.startsWith("mock-")) {
  //     // console.info("Journey shared route, skipping check");
  //     return;
  //   }
  //   const onboardingStore = useOnboardingStore();
  //   console.info("Checking onboarding...");
  //   if (onboardingStore.completed) {
  //     console.info("Onboarding already completed");
  //     return;
  //   }
  //   return navigateTo("/onboarding");
});
