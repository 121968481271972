export const useLifecycleStore = defineStore("lifecycle", {
  state: () => ({
    initialized: false,
  }),
  actions: {
    setInitialized(value: boolean) {
      this.initialized = value;
    },
  },
});
