import type { StoryVersion } from "~/api/types";
import { BRDGE_PROJECT_ID } from "~/lib/config";

export const useStoryVersionStore = defineStore(
  `storyVersion_${BRDGE_PROJECT_ID}`,
  {
    state: (): {
      storyVersion: StoryVersion;
    } => ({
      storyVersion: null,
    }),
    actions: {
      setStoryVersion(version: StoryVersion) {
        this.storyVersion = version;
      },
    },
    persist: {
      storage: persistedState.localStorage,
    },
  }
);
