import { default as _91slug_93tpXL3n5B3yMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/i/frame/[slug].vue?macro=true";
import { default as indexDnVNG7fVevMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/index.vue?macro=true";
import { default as index1cj6N5ke6yMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/journey/index.vue?macro=true";
import { default as _91id_933Ab211lCIFMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/journey/shared/[id].vue?macro=true";
import { default as _91bugReportQrCode_93FfSz0rfC9rMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/bug_report/[bugReportQrCode].vue?macro=true";
import { default as closeGBMeKMyvCHMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/close.vue?macro=true";
import { default as _91slug_93dngYnqii5XMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/frame/[slug].vue?macro=true";
import { default as _91hash_932vhIJyJCorMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/i/[hash].vue?macro=true";
import { default as index9BmiaPDPKEMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/index.vue?macro=true";
import { default as infoQVt8GoKvayMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/info.vue?macro=true";
import { default as indexvkxUpkQeCZMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/journey/index.vue?macro=true";
import { default as _91id_938HFCENs4GGMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/journey/shared/[id].vue?macro=true";
import { default as _91hash_93KU7gImMcxeMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/li/[hash].vue?macro=true";
import { default as _91sessionHash_93rrVTt2DHADMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue?macro=true";
import { default as _91installationToken_93F500hE82pNMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue?macro=true";
import { default as onboardingXrC30G9brUMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/onboarding.vue?macro=true";
import { default as _91id_93iwlrcRadNlMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/s/[id].vue?macro=true";
import { default as _91id_93wz7tedWeoTMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/scenes/[id].vue?macro=true";
import { default as settingsYkIaQAjEOGMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/settings.vue?macro=true";
import { default as setupFhX7BryU04Meta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/setup.vue?macro=true";
import { default as component_45stubK06KzAlOAAMeta } from "/home/runner/work/wow-guide/wow-guide/frontend-wg3/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: _91slug_93tpXL3n5B3yMeta?.name ?? "locale-wg2-i-frame-slug",
    path: _91slug_93tpXL3n5B3yMeta?.path ?? "/:locale()/wg2/i/frame/:slug()",
    meta: _91slug_93tpXL3n5B3yMeta || {},
    alias: _91slug_93tpXL3n5B3yMeta?.alias || [],
    redirect: _91slug_93tpXL3n5B3yMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/i/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexDnVNG7fVevMeta?.name ?? "locale-wg2",
    path: indexDnVNG7fVevMeta?.path ?? "/:locale()/wg2",
    meta: indexDnVNG7fVevMeta || {},
    alias: indexDnVNG7fVevMeta?.alias || [],
    redirect: indexDnVNG7fVevMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/index.vue").then(m => m.default || m)
  },
  {
    name: index1cj6N5ke6yMeta?.name ?? "locale-wg2-journey",
    path: index1cj6N5ke6yMeta?.path ?? "/:locale()/wg2/journey",
    meta: index1cj6N5ke6yMeta || {},
    alias: index1cj6N5ke6yMeta?.alias || [],
    redirect: index1cj6N5ke6yMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933Ab211lCIFMeta?.name ?? "locale-wg2-journey-shared-id",
    path: _91id_933Ab211lCIFMeta?.path ?? "/:locale()/wg2/journey/shared/:id()",
    meta: _91id_933Ab211lCIFMeta || {},
    alias: _91id_933Ab211lCIFMeta?.alias || [],
    redirect: _91id_933Ab211lCIFMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/[locale]/wg2/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: _91bugReportQrCode_93FfSz0rfC9rMeta?.name ?? "bug_report-bugReportQrCode",
    path: _91bugReportQrCode_93FfSz0rfC9rMeta?.path ?? "/bug_report/:bugReportQrCode()",
    meta: _91bugReportQrCode_93FfSz0rfC9rMeta || {},
    alias: _91bugReportQrCode_93FfSz0rfC9rMeta?.alias || [],
    redirect: _91bugReportQrCode_93FfSz0rfC9rMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/bug_report/[bugReportQrCode].vue").then(m => m.default || m)
  },
  {
    name: closeGBMeKMyvCHMeta?.name ?? "close",
    path: closeGBMeKMyvCHMeta?.path ?? "/close",
    meta: closeGBMeKMyvCHMeta || {},
    alias: closeGBMeKMyvCHMeta?.alias || [],
    redirect: closeGBMeKMyvCHMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/close.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93dngYnqii5XMeta?.name ?? "frame-slug",
    path: _91slug_93dngYnqii5XMeta?.path ?? "/frame/:slug()",
    meta: _91slug_93dngYnqii5XMeta || {},
    alias: _91slug_93dngYnqii5XMeta?.alias || [],
    redirect: _91slug_93dngYnqii5XMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/frame/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91hash_932vhIJyJCorMeta?.name ?? "i-hash",
    path: _91hash_932vhIJyJCorMeta?.path ?? "/i/:hash()",
    meta: _91hash_932vhIJyJCorMeta || {},
    alias: _91hash_932vhIJyJCorMeta?.alias || [],
    redirect: _91hash_932vhIJyJCorMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/i/[hash].vue").then(m => m.default || m)
  },
  {
    name: index9BmiaPDPKEMeta?.name ?? "index",
    path: index9BmiaPDPKEMeta?.path ?? "/",
    meta: index9BmiaPDPKEMeta || {},
    alias: index9BmiaPDPKEMeta?.alias || [],
    redirect: index9BmiaPDPKEMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: infoQVt8GoKvayMeta?.name ?? "info",
    path: infoQVt8GoKvayMeta?.path ?? "/info",
    meta: infoQVt8GoKvayMeta || {},
    alias: infoQVt8GoKvayMeta?.alias || [],
    redirect: infoQVt8GoKvayMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/info.vue").then(m => m.default || m)
  },
  {
    name: indexvkxUpkQeCZMeta?.name ?? "journey",
    path: indexvkxUpkQeCZMeta?.path ?? "/journey",
    meta: indexvkxUpkQeCZMeta || {},
    alias: indexvkxUpkQeCZMeta?.alias || [],
    redirect: indexvkxUpkQeCZMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/journey/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938HFCENs4GGMeta?.name ?? "journey-shared-id",
    path: _91id_938HFCENs4GGMeta?.path ?? "/journey/shared/:id()",
    meta: _91id_938HFCENs4GGMeta || {},
    alias: _91id_938HFCENs4GGMeta?.alias || [],
    redirect: _91id_938HFCENs4GGMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/journey/shared/[id].vue").then(m => m.default || m)
  },
  {
    name: _91hash_93KU7gImMcxeMeta?.name ?? "li-hash",
    path: _91hash_93KU7gImMcxeMeta?.path ?? "/li/:hash()",
    meta: _91hash_93KU7gImMcxeMeta || {},
    alias: _91hash_93KU7gImMcxeMeta?.alias || [],
    redirect: _91hash_93KU7gImMcxeMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/li/[hash].vue").then(m => m.default || m)
  },
  {
    name: _91sessionHash_93rrVTt2DHADMeta?.name ?? "mock-channel-client-installationPublicId-juaUserPublicId-juaUserToken-sessionHash",
    path: _91sessionHash_93rrVTt2DHADMeta?.path ?? "/mock/channel/client/:installationPublicId()/:juaUserPublicId()/:juaUserToken()/:sessionHash()",
    meta: _91sessionHash_93rrVTt2DHADMeta || {},
    alias: _91sessionHash_93rrVTt2DHADMeta?.alias || [],
    redirect: _91sessionHash_93rrVTt2DHADMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/mock/channel/client/[installationPublicId]/[juaUserPublicId]/[juaUserToken]/[sessionHash].vue").then(m => m.default || m)
  },
  {
    name: _91installationToken_93F500hE82pNMeta?.name ?? "mock-channel-installation-installationPublicId-installationToken",
    path: _91installationToken_93F500hE82pNMeta?.path ?? "/mock/channel/installation/:installationPublicId()/:installationToken()",
    meta: _91installationToken_93F500hE82pNMeta || {},
    alias: _91installationToken_93F500hE82pNMeta?.alias || [],
    redirect: _91installationToken_93F500hE82pNMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/mock/channel/installation/[installationPublicId]/[installationToken].vue").then(m => m.default || m)
  },
  {
    name: onboardingXrC30G9brUMeta?.name ?? "onboarding",
    path: onboardingXrC30G9brUMeta?.path ?? "/onboarding",
    meta: onboardingXrC30G9brUMeta || {},
    alias: onboardingXrC30G9brUMeta?.alias || [],
    redirect: onboardingXrC30G9brUMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: _91id_93iwlrcRadNlMeta?.name ?? "s-id",
    path: _91id_93iwlrcRadNlMeta?.path ?? "/s/:id()",
    meta: _91id_93iwlrcRadNlMeta || {},
    alias: _91id_93iwlrcRadNlMeta?.alias || [],
    redirect: _91id_93iwlrcRadNlMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/s/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wz7tedWeoTMeta?.name ?? "scenes-id",
    path: _91id_93wz7tedWeoTMeta?.path ?? "/scenes/:id()",
    meta: _91id_93wz7tedWeoTMeta || {},
    alias: _91id_93wz7tedWeoTMeta?.alias || [],
    redirect: _91id_93wz7tedWeoTMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/scenes/[id].vue").then(m => m.default || m)
  },
  {
    name: settingsYkIaQAjEOGMeta?.name ?? "settings",
    path: settingsYkIaQAjEOGMeta?.path ?? "/settings",
    meta: settingsYkIaQAjEOGMeta || {},
    alias: settingsYkIaQAjEOGMeta?.alias || [],
    redirect: settingsYkIaQAjEOGMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: setupFhX7BryU04Meta?.name ?? "setup",
    path: setupFhX7BryU04Meta?.path ?? "/setup",
    meta: setupFhX7BryU04Meta || {},
    alias: setupFhX7BryU04Meta?.alias || [],
    redirect: setupFhX7BryU04Meta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/pages/setup.vue").then(m => m.default || m)
  },
  {
    name: component_45stubK06KzAlOAAMeta?.name ?? undefined,
    path: component_45stubK06KzAlOAAMeta?.path ?? "/de/wg2",
    meta: component_45stubK06KzAlOAAMeta || {},
    alias: component_45stubK06KzAlOAAMeta?.alias || [],
    redirect: component_45stubK06KzAlOAAMeta?.redirect,
    component: () => import("/home/runner/work/wow-guide/wow-guide/frontend-wg3/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]